<script lang="typescript">
import { createEventDispatcher } from 'svelte'

const dispatch = createEventDispatcher()

export let data: {
    content: string
    actions: Action[]
}

type Cb = () => void

type Action = {
    name: string,
    label: string,
    type?: string,
    fn: ({ close }: { close: Cb}) => void
}

const { content, actions }  = data

function close() {
    dispatch('closemodal')
}
</script>

<div class="modal-body">
    <div class="content">
        {@html content}
    </div>
</div>

{#if actions.length}
    <div class="modal-footer">
        {#each actions as action}
            <div class={`btn btn-${action.type || 'primary'}` } data-action={action.name} on:click|preventDefault={() => action.fn({ close })}>
                {action.label}
            </div>
        {/each}
    </div>
{/if}
